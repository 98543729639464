import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../slices";
import Instance from "../../types/Instance";

export const useRedirectBack = (instance: Instance) => {
    const navigate = useNavigate();
    const isAdmin = useSelector((state: RootState) => state.user.isAdmin);

    const redirectBack = () => {
        if (isAdmin) {
            return navigate(-1)
        } else {
            return navigate("/my-sessions?trainingId=" + instance?.practicalExercise?.trainingModule?.trainingSession?.id)
        }
    }
    return {redirectBack}
}

export default useRedirectBack;